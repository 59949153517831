import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import postItHeader from "../../images/post-it-grey.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubProceduralPostIt() {
  window.open(
    "https://github.com/johannagranstroem/TDDD27-Advanced-Web-Programming",
    "_blank"
  )
}

const postItPage = () => (
  <Main>
    <SEO title="Post It" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-PostIt" src={postItHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">POST-IT</p>
          <p class="project-header-text-grey">WEB DEVELOPMENT</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Languages</p>
        <p class="project-bulletpoint-text"> &#9642; Vue</p>
        <p class="project-bulletpoint-text"> &#9642; JavaScript</p>
        <p class="project-bulletpoint-text"> &#9642; HTML</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-05-30</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          Together with one other project member, we developed a webpage where
          you can log in with an account to organize and keep track of notes.
          It's built using <b>MongoDB</b> as the database, <b>Express</b>,{" "}
          <b>Vue</b>, <b>Node.js</b>, and authentication is handled by Okta.
          <br />
          <br />
          This was my first project developing a Full-Stack webpage and I
          learned how frontend and backend communicate and work together.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubProceduralPostIt()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default postItPage
